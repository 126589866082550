<template>
  <v-container>

    <v-row v-if="isMobile">
      <v-col>
        <v-sheet
            v-for="(event, i) in timeline"
            :key="i"
            class="mb-3"
        >
          <v-card class="text-left">
            <v-card-title v-html="event.title" class="text-break" :class="`headline font-weight-light mb-4 ${event.color}--text`"></v-card-title>
            <v-card-subtitle class="text-justify" v-html="'<b>' + event.date + '</b><br />' + event.desc ">
            </v-card-subtitle>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-timeline>
        <v-timeline-item
            v-for="(event, i) in timeline"
            :key="i"
            :color="event.color"
            small
        >
          <template v-slot:opposite>
            <span
                :class="`headline font-weight-bold ${event.color}--text`"
                v-text="event.date"
            ></span>
          </template>

          <v-card class="text-left">
            <v-card-title v-html="event.title" class="text-break" :class="`headline font-weight-light mb-4 ${event.color}--text`"></v-card-title>
            <v-card-subtitle class="text-justify" v-html="event.desc ">
              <b v-if="isMobile">{{event.date}}</b>
              </v-card-subtitle>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-row>
  </v-container>
</template>

<script>
export default {

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
    return false;
    }
  },

  data: () => ({
    //TODO: missing translations or restful request
    timeline: [
      {
        color: 'green',
        date: '29.11.2021',
        title: 'System kolejkowy w Wydziale Komunikacji Starostwa Powiatowego w Zielonej Górze oraz w Delegaturze w Sulechowie',
        desc: 'W Wydziale Komunikacji Starostwa Powiatowego w Zielonej Górze oraz w Delegaturze w Sulechowie pod koniec 2021 zainstalowaliśmy system kolejkowy SieglaQMS. Każda z lokalizacji została wyposażona w nowoczesny automat biletowy, ekran przywoławczy wykonany w technologii LCD oraz ' +
            'mobilne przywoływacze stanowiskowe dla pracowników realizujących obsługę.'
      },
      {
        color: 'orange',
        date: '07.10.2020',
        title: 'System kolejkowy w Wojewódzkim Szpitalu Specjalistycznym w Jastrzębiu-Zdroju',
        desc: 'Firma Platforma Biznesu Sp. z o.o. zrealizowała przetarg na zakup, dostawę i montaż systemu kolejkowego w ramach projektu: ' +
            '"Poprawa jakości i dostępności do świadczeń ochrony zdrowia z zakresu ambulatoryjnej specjalistycznej i podstawowej opieki zdrowotnej w ' +
            'Wojewódzkim Szpitalu Specjalistycznym Nr 2 w Jastrzębiu–Zdroju" - powtórzenie Znak sprawy: BZP.38.382-30.19. Przetarg był podzielony ' +
            'na dwa etapy, według pięter przychodni przyszpitalnej. W ramach I etapu dostarczyliśmy 2 automaty biletowe, 5 monitorów zbiorczych, ' +
            '18 monitorów stanowiskowych zainstalowanych nad wejściami do gabinetów oraz 5 drukarek stanowiskowych w rejestracji. ' +
            'W II etapie dostarczyliśmy 2 biletomaty, 5 ekranów zbiorczych, 16 ekranów stanowiskowych oraz 5 drukarek. ' +
            '<br /><br />Wprowadzone rozwiązanie monitorowane jest przez dedykowany serwer zarządczy oraz zintegrowane ze szpitalnym systemem HIS ' +
            'umożliwiając realizację spraw w systemie kolejkowym na podstawie wizyt zaplanowanych w systemie dziedzinowym. ' +
            'Wdrożenie zostało rozszerzone funkcjonalnie o multimedialny pakiet SieglaMMS umożliwiając prezentację treści multimedialnych w wydzielonej przestrzeni ekranów wyświetlających stan kolejki.'
      },
      {
        color: 'green',
        date: '05.06.2020',
        title: 'Realizacja projektu B+R',
        desc: 'Firma Platforma Biznesu Sp. z o.o. zrealizowała projekt pn. „Opracowanie skalowalnej platformy zdalnego zarządzania aplikacjami w systemach GNU/LINUX”, współfinansowanego ze środków Unii Europejskiej w ramach Regionalnego Programu Operacyjnego Województwa Zachodniopomorskiego na lata 2014-2020. Okres realizacji projektu od 01.03.2018 r. do 30.06.2019 r. Wartość projektu: 802 446,22 zł, wydatki kwalifikowalne: 724  751,11 zł a wartość dofinansowania: 551 265,40 zł. Głównym celem projektu było przeprowadzenie badań przemysłowych eksperymentalnych pozwalających na stworzenie skalowalnej platformy do serwowania treści multimedialnych. ' +
            'Wyniki badań zawarto w <a href="/files/mms/mms_raport_koncowy.pdf">raporcie końcowym.</a><br /><br />' +
            '<a href="/files/mms/Tablica_Platforma_Biznesu.docx">Tablica informacyjna.</a>'
      },
      {
        color: 'orange',
        date: '13.12.2019',
        title: 'System kolejkowy w Samodzielnym Publicznym Zakładzie Opieki Zdrowotnej Ministerstwa Spraw Wewnętrznych i Administracji w Szczecinie',
        desc: 'W 2019 roku firma Platforma Biznesu Sp. z o.o. zrealizowała przetarg o nazwie: "Dostawa i uruchomienie systemu kolejkowego dla SP ZOZ MSWiA' +
            'w Szczecinie (powtórka)" o numerze 46/2019 dla Samodzielnego Publicznego Zakładu Opieki Zdrowotnej Ministerstwa Spraw Wewnętrznych i Administracji w Szczecinie. Przetarg obejmował wymianę przestarzałego systemu nowoczesnym rozwiązaniem poprzez dostarczenie systemu SieglaQMS z automatem biletowym oraz wymianę matryc LED na nowoczesne monitory LCD.'
      },
      {
        color: 'green',
        date: '22.10.2019',
        title: 'System kolejkowy w Wydziale Komunikacji,Transportu i Dróg Starostwa Powiatowego w Szamotułach',
        desc: 'Szamotulski Wydział Komunikacji, Transportu i Dróg wyposażony został w 2 automaty biletowe, 2 ekrany informacyjno-przywoławcze oraz niezależny system nagłośnienia. Pracownicy korzystają przy obsłudzę klientów z bezprzewodowych terminali, umożliwiając zachowanie mobilnej charakterystyki pracy.'
      },

      {
        color: 'orange',
        date: '01.07.2019',
        title: 'System kolejkowy w Wydziale Komunikacji Starostwa Powiatowego w Wągrowcu',
        desc: 'W 2019 dostarczyliśmy system kolejkowy do specjalnie zaaranżowanej przestrzeni w Wydziale Komunikacji Starostwa Powiatowego w Wągrowcu.'
      },
      {
        color: 'orange',
        date: '20.05.2019',
        title: 'System Kolejkowy w Zarządzie Dróg i Transportu Miejskiego w Szczecinie',
        desc: 'W lipcu 2018 roku uruchomiliśmy system kolekowy w Zarządzie Dróg i Transportu Miejskiego w Szczecinie.\n' +
            '\n' +
            'Dostarczyliśmy niezbędny sprzęt i oprogramowanie w tym zabudowany biletomat oraz przywoływacze stanowiskowe w formie bezprzewodowych urządzeń mobilnych (tablety).'
      },
      {
        color: 'green',
        date: '03.04.2019',
        title: 'System kolejkowy w Wydziale Komunikacji i Dróg Starostwa Powiatowego w Międzychodzie',
        desc: 'System kolejkowy w Starostwie Powiatowym w Międzychodzie wyposażony został w biletomat z materiału Corian&reg;, ekran przywoławczy oraz mobilne przywoływacze stanowiskowe do obsługi petentów. Jest to podstawowa konfiguracja pozwalająca usprawnić pracę urzędu.'
      },

      {
        color: 'orange',
        date: '18.12.2018',
        title: 'System kolejkowy w Wydziale Komunikacji Starostwa Powiatowego w Górze',
        desc: 'Pod koniec 2018 roku dostarczyliśmy system kolejkowy dla Wydziału Komunikacji Starostwa Powiatowego w Górze. Pierwszy etap zamówienia składał się z biletomatu wykonanego z materiału Corian&reg; oraz wyświetlacza stanowiskowego wraz z tabletami służacymi do wzywania klientów. W drugiej części zamówienia zainstalowany został drugi ekran przywoławczy do realizacji spraw związanych z uprawnieniami do kierowania pojazdami.'
      },
      {
        color: 'orange',
        date: '07.04.2018',
        title: 'System kolejkowy w Wydziale Komunikacji w Powiecie Nowotomyskim',
        desc: 'Od kwietnia 2018 roku w Wydziale Komunikacji w Powiecie Nowotomyskim można korzystać z systemu kolejkowego.\n' +
            '\n' +
            'Dostarczyliśmy sprzęt i oprogramowanie, które umożliwi rezerwację wizyty prze internet oraz monitorowanie wizyty online. W placówce znajduje się biletomat z materiału Corian oraz przywoływacze stanowiskowe w formie bezprzewodowych urządzeń mobilnych (tablety).'
      },
      {
        color: 'green',
        date: '07.04.2018',
        title: 'ZAPYTANIE OFERTOWE nr 02/06/2017',
        desc: 'Przedmiotem zamówienia jest wykonanie materiałów promocyjnych związanych z uzyskanie dofinansowania projektu pn. „Wsparcie ekspansji firmy Platforma Biznesu Sp. z o.o. poprzez udział w targach zagranicznych”, w odpowiedzi na konkurs nr RPZP.01.15.00-IZ.00-32-001/16 w ramach działania 1.15 Wsparcie kooperacji przedsiębiorstw, mający na celu ukierunkowanie przedsiębiorstw na zwiększenie stopnia kooperacji z partnerami krajowymi, jak i zagranicznymi, co pozwoli lepiej wykorzystać istniejący potencjał rozwoju gospodarki regionu, szczególnie w obszarze innowacyjnych MŚP ogłoszony przez Instytucja Zarządzająca Regionalnym Programem Operacyjnym Województwa Zachodniopomorskiego 2014-2020.'
      },
      {
        color: 'orange',
        date: '07.04.2018',
        title: 'System kolejkowy w Starostwie Powiatowym w Wolsztynie',
        desc: 'W styczniu 2017 roku dostarczyliśmy system kolejkowy do Starostwa Powiatowego w Wolsztynie. Zamówienie polegało na wykonaniu, dostarczeniu oraz wdrożeniu systemu kolejkowego do rejestracji wraz ze sprzętem niezbędnym do prawidłowego funkcjonowania systemu. Biletomat został wykonany ze stali nierdzewnej malowanej proszkowo oraz wyposażony w 19\'\' ekran dotykowy z funkcją multitouch.'
      },
      {
        date: '21.11.2017',
        title: 'Zamek Książąt Pomorskich w Szczecinie',
        desc: 'W listopadzie 2016 roku dostarczyliśmy aplikację KABINET 2.0 wraz z kioskiem multimedialnym dla Zamku Książąt Pomorskich w Szczecinie. Zamówienie dla Zamku Książąt Pomorskich w Szczecinie obejmowało przygotowanie aplikacji KABINET 2.0 oraz dostarczenie interaktywnego kiosku multimedialnego 55". Kabinet 2.0 stanowi uzupełnienie wystawy stałej o interaktywną wersją Kabinetu Pomorskiego zniszczonego w trakcie II wojny światowej. Zamówienie obejmowało również instalację oraz konfigurację kiosku multimedialnego wraz z aplikacją, przeszkolenie pracowników w sposób umożliwiający samodzielną ekspolatację kiosku, aplikacji i oprogramowania CMS.'
      },
      {
        color: 'orange',
        date: '21.11.2016',
        title: 'System kolejkowy w Urzędzie Miejskim w Słupsku',
        desc: 'W kwietniu 2016 roku dostarczyliśmy system kolejkowy do Wydziału Komunikacji w Urzędzie Miejskim w Słupsku Zamówienie polegało na wykonaniu, dostarczeniu oraz wdrożeniu systemu kolejkowego do rejestracji wraz ze sprzętem niezbędnym do prawidłowego funkcjonowania systemu. Biletomat został wykonany ze stali nierdzewnej malowanej proszkowo oraz wyposażony w 19\'\' ekran dotykowy z funkcją multitouch.'
      },
      {
        color: 'blue',
        date: '21.09.2016',
        title: 'Baltic Tall Ships Regatta 2015',
        desc: 'Podczas tegorocznych Baltic Tall Ships Regatta - Platforma Biznes wzięła udział w multimedialnej odsłonie promującej miasto Szczecin. Na prośbę Urzędu Miasta w Szczecinie, nasza firma przygotowała kioski multimedialne z aplikacją promującą kampanię społeczną „Szczecin_z całego serca polecam AMBASADOR”, która została przygotowana specjalnie dla mieszkańców i turystów.'
      },
      {
        color: 'secondary',
        date: '12.08.2016',
        title: 'Urząd Rejestracji Produktów Leczniczych, Wyrobów Medycznych i Produktów Biobójczych',
        desc: 'W grudniu 2015 roku wykonaliśmy stronę internetową wraz z systemem CMS dla  Urzędu Rejestracji Produktów Leczniczych, Wyrobów Medycznych i Produktów Biobójczych w Warszawie.\n' +
            '\n' +
            'Realizacja  zamówienie polegała na zaprojektowaniu, wykonaniu i dostawie systemu zarządzania treścią (CMS) wraz z niezbędnymi licencjami, szkoleniami, wsparciem oraz jego instalacją, uruchomieniem, integracją, migracją danych i dostosowaniem do obsługi serwisów internetowych Urzędu Rejestracji Produktów Leczniczych, Wyrobów Medycznych i Produktów Biobójczych.'
      },
      {
        color: 'orange',
        date: '08.08.2016',
        title: 'Umowa z Instytutem Meteorologii i Gospodarki Wodnej',
        desc: 'Mamy przyjemność poinformować, że Platforma Biznesu podpisała umowę z Instytutem Meteorologii i Gospodarki Wodnej na Świadczenie usług utrzymaniowych Kiosków Meteorologicznych i Multimedialnych oraz dostarczenie i udostępnienie systemu zarządzania i serwowania treści na potrzeby IMGW-PIB.'
      },
    ],
  }),
}
</script>
